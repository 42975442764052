import React, { useEffect, useReducer, useRef } from "react";
import Promotion from "./Promotion";
import styles from "./Board.module.css";
import { getPieceId, read, write } from "../chessUtils/fen";
import { playSound } from "../../lib/sound";
import { ANIMATION_DURATION, computeAnims } from "./animations";
import DragBoard from "./DragBoard";
import Pieces from "./Pieces";
import { key2pos } from "../chessUtils/util";
import NumbersLetters from "./NumbersLetters";
import { ChessSets } from "@chesshotel/constants";

export function addSound(hasCapture: boolean, sound: boolean) {
  if (sound) {
    if (hasCapture) {
      playSound("take");
    } else {
      playSound("move");
    }
  }
}

const initialState = {
  promotionVisible: false,
  animate: false,
  fadeOuts: [],
  moves: [],
  playingWhite: true,
  lastMove: null,
};

function boardReducer(state, action) {
  switch (action.type) {
    case "setPosition":
      return {
        ...state,
        position: action.position,
        fadeOuts: action.fadeOuts || [],
        moves: action.moves || [],
        animate: action.animate,
        lastMove: action.lastMove,
      };
    case "setAnimate":
      return {
        ...state,
        animate: action.animate,
      };
    case "removeFadeOuts":
      return {
        ...state,
        fadeOuts: [],
        moves: [],
      };
    case "setLastMove":
      return {
        ...state,
        lastMove: action.lastMove,
      };
    case "setPromotionVisible":
      return {
        ...state,
        promotionVisible: action.promotionVisible,
      };
    case "setPlayingWhite":
      return {
        ...state,
        playingWhite: action.playingWhite,
      };

    default:
      throw new Error();
  }
}

export default function Board(props) {
  let {
    onMoveMade = null,
    bestMove = null,
    chessTermArrows = null,
    autoQueening = null,
    lastMoveColor = null,
    showLastMove = false,
    fen,
    puzzleDone,
    lastPuzzleMove,
    highLightKey = null,
    possibleMoves,
    size,
    animate: doAnimate = true,
    freeMove = false,
    settings = {
      sound: true,
      autoQueening: false,
      pieceDestinations: true,
      lastMove: true,
      chessSet: ChessSets.CHESSHOTEL,
    },
  } = props;

  const boardSize = size;

  const [state, dispatch] = useReducer(boardReducer, initialState);

  const { sound, chessSet } = settings;
  const {
    position,
    fadeOuts,
    lastMove,
    promotionVisible,
    playingWhite,
    animate,
    moves,
  } = state;
  const pieceRefs = useRef({});
  const squareSize = boardSize / 8;

  useEffect(() => {
    if (fen) {
      fen = fen.split(" ")[0];
      const positionFen = position && write(position);

      if (positionFen === fen) {
        return;
      }
      const newPosition = read(fen);

      const willAnimate = doAnimate && position;
      if (willAnimate) {
        const { hasCapture, toBoard, fadeOuts, moves } = computeAnims(
          position,
          newPosition,
          playingWhite,
          lastMove
        );
        dispatch({
          type: "setPosition",
          position: toBoard,
          fadeOuts,
          moves,
          lastMove: props.lastMove,
          animate: true,
        });
        setTimeout(() => {
          addSound(hasCapture, sound);
          dispatch({
            type: "removeFadeOuts",
          });
        }, ANIMATION_DURATION);
      } else {
        dispatch({
          type: "setPosition",
          position: newPosition,
          animate: false,
          lastMove: props.lastMove,
        });
      }
    }
  }, [fen]);

  useEffect(() => {
    dispatch({ type: "setPlayingWhite", playingWhite: props.playingWhite });
  }, [props.playingWhite]);

  useEffect(() => {
    if (!doAnimate) {
      dispatch({ type: "setLastMove", lastMove: null });
    }
  }, [doAnimate]);

  function movePiece({ from, to }) {
    doMovePiece(from, to, position);
  }

  function doMovePiece(from: string, to: string, oldPosition: object) {
    const hasCapture = position[to];
    const piece = position[from];

    const newPosition = { ...oldPosition, [to]: piece };
    delete newPosition[from];

    const lastMove = { from, to, piece };
    const fen = write(newPosition);
    const toPos = key2pos(to);

    dispatch({ type: "setPosition", position: newPosition, lastMove });

    if (piece.role === "pawn" && (toPos[1] === 8 || toPos[1] === 1)) {
      if (autoQueening) {
        onMoveMade({ from, to, promotion: "q", fen });
      } else {
        dispatch({ type: "setPromotionVisible", promotionVisible: true });
      }
    } else {
      onMoveMade({ from, to, fen });
    }

    addSound(hasCapture, sound);
  }

  function makePromotion(role: string, promotion: string, color: string) {
    const { from, to } = lastMove;

    const pieceIds = Object.values(position).map((piece: any) => piece.id);
    const id = getPieceId(pieceIds, color + role, 0);

    const newPiece = {
      role,
      color,
      id,
    };

    const newPosition = { ...position, [to]: newPiece };
    dispatch({ type: "setPosition", position: newPosition });

    const fen = write(newPosition);

    const move = {
      from,
      to,
      promotion,
      fen,
    };
    dispatch({ type: "setPromotionVisible", promotionVisible: false });
    onMoveMade(move);
  }

  const boardClass = styles[chessSet];
  const boardStyle = boardSize
    ? {
        width: boardSize + "px",
        height: boardSize + "px",
      }
    : {};

  return (
    <div
      className="relative flex flex-col select-none md:rounded-md bg-white 
      border-0 border-athens-gray-150 md:border md:border-b-[3px] md:p-5"
    >
      <NumbersLetters playingWhite={playingWhite} />
      <div
        className={`relative bg-cover select-none w-[100vw] h-[100vw] 
            md:w-[504px] md:h-[504px] md:rounded ${boardClass}`}
        style={boardStyle}
      >
        {promotionVisible && (
          <Promotion
            playingWhite={playingWhite}
            makePromotion={makePromotion}
            settings={settings}
          />
        )}
        <DragBoard
          freeMove={freeMove}
          pieceRefs={pieceRefs}
          possibleMoves={possibleMoves}
          squareSize={squareSize}
          playingWhite={playingWhite}
          highLightKey={highLightKey}
          movePiece={movePiece}
          position={position}
          bestMove={bestMove}
          chessTermArrows={chessTermArrows}
          showLastMove={showLastMove}
          lastMove={lastMove}
          lastMoveColor={lastMoveColor}
          settings={settings}
          lastPuzzleMove={lastPuzzleMove}
          puzzleDone={puzzleDone}
        >
          <Pieces
            position={position}
            fadeOuts={fadeOuts}
            pieceRefs={pieceRefs}
            playingWhite={playingWhite}
            animate={animate}
            moves={moves}
            chessSet={settings.chessSet}
            freeMove={freeMove}
          />
        </DragBoard>
      </div>
      <div className="hidden">
        <img src="/images/promotion-bg.png" />
        <img src={`/images/pieces/${chessSet}/bK.svg`} />
        <img src={`/images/pieces/${chessSet}/bQ.svg`} />
        <img src={`/images/pieces/${chessSet}/bR.svg`} />
        <img src={`/images/pieces/${chessSet}/bN.svg`} />
        <img src={`/images/pieces/${chessSet}/bB.svg`} />
        <img src={`/images/pieces/${chessSet}/bP.svg`} />
        <img src={`/images/pieces/${chessSet}/wK.svg`} />
        <img src={`/images/pieces/${chessSet}/wQ.svg`} />
        <img src={`/images/pieces/${chessSet}/wR.svg`} />
        <img src={`/images/pieces/${chessSet}/wN.svg`} />
        <img src={`/images/pieces/${chessSet}/wB.svg`} />
        <img src={`/images/pieces/${chessSet}/wP.svg`} />
        <img src={`/images/board/${chessSet}.svg`} />
      </div>
    </div>
  );
}
