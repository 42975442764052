import React from "react";
import { getPosFromKey } from "../chessUtils/util";

// function renderArrow(
//   fromPos,
//   toPos,
//   i = null,
//   color: string = "#333333",
//   opacity: number = 0.3,
//   shortenBy = 4, // Length to shorten the arrow by, in percentage units
//   borderColor: string = "#ffffff", // Border color for the arrow
//   borderWidth: number = 0.5 // Border width for the rectangle in percentage units
// ) {
//   const m = 1;
//   const dx = toPos[0] - fromPos[0];
//   const dy = toPos[1] - fromPos[1];
//   const angle = Math.atan2(dy, dx);

//   // Arrow rectangle dimensions
//   const rectLength = Math.sqrt(dx * dx + dy * dy) * 12.5 - shortenBy * 2; // Length shortened
//   const rectWidth = 2.5; // Adjust the width of the arrow

//   // Calculate the position of the rectangle
//   const rectX = fromPos[0] * 12.5 + 12.5 / 2; // Start from the center of `fromPos`
//   const rectY = fromPos[1] * 12.5 + 12.5 / 2 - rectWidth / 2; // Center vertically

//   const rand = Math.random();
//   const arrowhead = "arrowhead" + rand;

//   return (
//     <>
//       <defs>
//         <marker
//           id={arrowhead}
//           orient="auto"
//           markerWidth={"6.25%"}
//           markerHeight={"3.125%"}
//           refX="2.1"
//           refY="2.0"
//         >
//           <path
//             d="M0,0 V4 L3,2 Z"
//             fill={color}
//             stroke={borderColor}
//             strokeWidth={borderWidth}
//           ></path>
//         </marker>
//       </defs>
//       {/* Arrow rectangle */}
//       <rect
//         key={i}
//         x={rectX + "%"}
//         y={rectY + "%"}
//         width={rectLength + "%"}
//         height={rectWidth + "%"}
//         fill={color}
//         stroke={borderColor}
//         strokeWidth={borderWidth + "%"}
//         transform={`rotate(${(angle * 180) / Math.PI}, ${
//           rectX + rectLength / 2
//         }%, ${rectY + rectWidth / 2}%)`}
//         opacity={opacity}
//       ></rect>
//       {/* Arrowhead */}
//       <line
//         stroke="none"
//         x1={toPos[0] * 12.5 + 12.5 / 2 + "%"}
//         y1={toPos[1] * 12.5 + 12.5 / 2 + "%"}
//         x2={toPos[0] * 12.5 + 12.5 / 2 + "%"}
//         y2={toPos[1] * 12.5 + 12.5 / 2 + "%"}
//         markerEnd={`url(#${arrowhead})`}
//         opacity="0"
//       />
//     </>
//   );
// }

function renderArrow(
  fromPos,
  toPos,
  i = null,
  color: string = "#333333",
  opacity: number = 0.3
) {
  const dx = toPos[0] - fromPos[0];
  const dy = toPos[1] - fromPos[1];
  const angle = Math.atan2(dy, dx);
  const xr = Math.cos(angle);
  const yr = Math.sin(angle);

  const shortenStart = 1; // Shorten from the starting square
  const shortenEnd = 1; // Shorten from the destination square

  // Scale the shortening factors
  const xs = xr * shortenStart;
  const ys = yr * shortenStart;
  const xe = xr * shortenEnd;
  const ye = yr * shortenEnd;

  // Scale the shortening factor
  // const xe = xr * shortenBy;
  // const ye = yr * shortenBy;

  // const xs = 0; // xr * 6.25;
  // const ys = 0; // yr * 6.25;

  const x1 = fromPos[0] * 12.5 + 12.5 / 2 + xs + "%";
  const y1 = fromPos[1] * 12.5 + 12.5 / 2 + ys + "%";

  const x2 = toPos[0] * 12.5 + 12.5 / 2 - xe + "%";
  const y2 = toPos[1] * 12.5 + 12.5 / 2 - ye + "%";

  const rand = Math.random();
  const arrowhead = "arrowhead" + rand;

  return (
    <React.Fragment key={"arrow" + i}>
      <defs>
        <marker
          id={arrowhead}
          orient="auto"
          markerWidth={"4"}
          markerHeight={"4"}
          refX="2"
          refY="2"
        >
          <path d="M0,0 V4 L3,2 Z" fill={color}></path>
        </marker>
      </defs>
      <line
        key={i}
        stroke={color}
        strokeWidth="2%"
        strokeLinecap="round"
        markerEnd={`url(#${arrowhead})`}
        opacity={opacity}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
      ></line>
    </React.Fragment>
  );
}

function renderCircle(fromPos, i) {
  const x1 = fromPos[0] * 12.5 + 12.5 / 2 + "%";
  const y1 = fromPos[1] * 12.5 + 12.5 / 2 + "%";

  return (
    <circle
      key={i}
      stroke="#333333"
      fill="none"
      strokeWidth="2%"
      opacity={0.3}
      cx={x1}
      cy={y1}
      r={"5%"}
    ></circle>
  );
}

function renderShapes(shapes) {
  return shapes.map((shape, i) => {
    if (
      shape.to == null ||
      (shape.to[0] == shape.from[0] && shape.to[1] == shape.from[1])
    ) {
      return renderCircle(shape.from, i);
    } else if (
      shape.from != null &&
      shape.to != null &&
      shape.from != shape.to
    ) {
      return renderArrow(shape.from, shape.to, i);
    }
  });
}

function renderBestMove(bestMove, playingWhite) {
  const posFrom = getPosFromKey(bestMove.from, playingWhite);
  const posTo = getPosFromKey(bestMove.to, playingWhite);

  return renderArrow(posFrom, posTo);
}

function renderChessTermArrows(chessTermArrows, playingWhite) {
  return chessTermArrows.map((chessTermArrow, i) => {
    const posFrom = getPosFromKey(chessTermArrow.from, playingWhite);
    const posTo = getPosFromKey(chessTermArrow.to, playingWhite);

    return renderArrow(posFrom, posTo, i, "#e97d35", 0.6);
  });
}

export default function SvgLayer({
  shapes = null,
  bestMove,
  chessTermArrows,
  playingWhite,
}) {
  return (
    <div className="absolute z-50 w-full h-full pointer-events-none">
      <svg width="100%" height="100%">
        {bestMove && renderBestMove(bestMove, playingWhite)}
        {shapes && renderShapes(shapes)}
        {chessTermArrows &&
          renderChessTermArrows(chessTermArrows, playingWhite)}
      </svg>
    </div>
  );
}
